/*------- 11. CTA ------ */

.cta-container {
  height: 50vh;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  /* Use cover instead of 100% auto for better image scaling */

  /* Add flexbox properties to center the content vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Add a max-width to the container for better responsiveness */
  max-width: 100%;

  /* Adjust button styling */
}

.cta-container2 {
  display: block;
  width: 100%;
  height: auto;
}

.line-style {
  width: 100%;
  text-align: center;
  background-color: $theme-color;

  p {
    color: white;
    font-size: 28px;
    line-height: 1em;
    padding: 10px 0px;
    font-weight: 550;

    @media (min-width: 2160px) {
      font-size: 32px;
    }

    @media #{$xs-layout} {
      font-size: 16px;
      padding: 5px 0px;
    }
  }
}

/* Media Queries */







@media (max-width: 600px) {
  /* Custom styles for screens with a maximum width of 400px */

  .cta-container {
    height: 18vh;
    /* Adjust the height for smaller screens */
  }


}