// CSS Custom Properties (Global Variables) ---------------
:root {
    --theme-color: #69C1B7;
    --theme-color-hover: #61b0a7;
}

// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$greatvibes: "Great Vibes", cursive;
$lobster: "Lobster", cursive;
$josefin: "Josefin Sans", sans-serif;

// text Colors ---------------
$white: #fcfcfc;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------
$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #d1cec5;
$bg-purple: #f0e0ff;
$bg-orange: #ffa900;


// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: var(--theme-color); // Referencing CSS custom property;
$theme-color-hover: var(--theme-color-hover);
$banner-top-color: #a49b9c;
$banner-bottom-color: #b1aca8;
$banner-menu-open-color: #a49b9c;
$theme-gray-1: #a49b9c;
$theme-gray-2: #b1aca8;
$theme-red: #fe5252;
$theme-badge-discount: #58585A;
$theme-brand-btn: #747474;
$theme-p: #4b4b4b;
$theme-breadcrumb: #5b5858;
$username-color: white;
$arrow-color: black;
$underline-color: #fafafa;
$phone-hours-wrapper-bg: $theme-gray-2;
$phone-hours-wrapper-hover-bg: $theme-color;
$phone-hours-wrapper-hover-text: white;
$hover-gray: #a49b9c;
$in-stock-green: #87ac40;
$delivery-orange: #e98c38;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

$login-grid-template: 43% 14% 43%;