/*------ 16. Breadcrumb style  --------*/

.breadcrumb-content {
  ul {
    li {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: $theme-breadcrumb;
      margin-right: 18px;
      text-transform: uppercase;
      position: relative;
      &::before {
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: $theme-breadcrumb;
        content: "";
        right: -21px;
        top: 12px;
        z-index: 99;
        transform: rotate(115deg);
      }
      &:last-child::before {
        display: none;
      }
      a {
        color: $theme-breadcrumb;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }

  span {
    & > span {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      @media #{$xs-layout} {
        font-size: 12px;
      }

      & > a {
        font-weight: 500;
        color: $theme-breadcrumb;
        &:hover {
          color: $theme-color;
        }
      }

      & > span {
        margin-right: 0;
        margin: 0 15px;
      }
    }
  }
}

@media #{$xs-layout} {
  .breadcrumb-mobile {
    padding: 0 !important;
  }
}