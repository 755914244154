.country-header {

  font-size: 15px;

  .dropdown {
    position: relative;
    margin-right: 6px;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: 1200px) {
      width: fit-content !important;
    }
  }

  .dropdown-countries {
    padding: 6px 0;
    margin-right: 6px;
    display: flex;
    align-items: center;
    color: white;
    @media #{$xs-layout} {
      margin-right: 0 !important;
    }
    @media (max-width: 1200px) {
      width: fit-content !important;
    }
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid grey;
    border-radius: 4px;
    z-index: 10;
    background-color:  $banner-bottom-color;
  }

  .dropdown-item,
  .dropdown-item-single {

    display: flex;
    align-items: center;
    color: white;
  }

  .dropdown-item-single {
    padding: 10px 0px;
    cursor: default;
  }

  .dropdown-item {
    cursor: pointer;
    padding: 10px;
  }

  .dropdown-item:hover {
    background: $hover-gray;
    color: white;
  }

  .dropdown-flag {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .selected-flag {
    margin-left: 8px;
  }

  .selected-country {
    background: $hover-gray;
  }

  .fa-caret-down {
    margin-right: 8px; 
  }

  .deliveryTopMob {
    span {
      font-weight: 500;
    }
    @media (max-width: 1200px) {
      text-align: right;
      justify-content: flex-end;
      margin-left:auto;
    }
    @media #{$md-layout} {
      p {
        font-size: 12px;
        line-height: 1.3;
      }
    }

    @media #{$xs-layout} {
      width: 50%;
      max-width: 200px;
      p {
        font-size: 11px;
        line-height: 1.3;
      }
    }

    @media #{$sm-layout} {
      max-width: 100% !important;
    }
  }

}