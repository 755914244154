.increase-last-child {
  tr:last-child {
    font-size: 1.5em;
  }
}

.order_placed_div {
  width: 100%;
  margin: auto;
  /* center the div horizontally and vertically */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.order_placed_div .spam-notice {
  font-style: italic;
  color: #666;
}

.order_placed_div .dl-horizontal dt {
  float: left;
  width: 170px;
  clear: left;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order_placed_div .dl-horizontal dd {
  margin-left: 130px;
}

.dd-ellipsis div > div {
  top: 7px !important;
}

#trustbadgeCustomCheckoutContainer {
  margin-bottom: 20px;
}

.order-placed-view-order {
  margin-bottom: 10px;
}

.order_placed_div #order-placed-view-order-button {
  background-color: #337ab7;
  border-color: #2e6da4;
  color: white;
  padding: 13px 25px 14px;
}

.order_placed_div #order-placed-view-order-button:hover {
  background-color: #286090;
  border-color: #204d74;
}

.order_failed_div {
  width: 75%;
  margin: auto;
  /* center the div horizontally and vertically */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.label {
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;

  &-order-refunded-color {
    background-color: #986dff;

    &[href]:focus,
    &[href]:hover {
      background-color: #743aff;
    }
  }

  &-service {
    background-color: #008000;
  }

  &-completed {
    background-color: #0ecc08;
  }

  &-offer {
    background-color: #008080;
  }

  &-pickup {
    background-color: #ad1a82;
  }

  &-Refunded_test {
    background-color: #d9534f;
  }

  &-offer_accepted {
    background-color: #295DA6;
  }

  &-vorkasse {
    background-color: #761c19;
  }

  &-long_time_not_paid {
    background-color: #854711;
  }

  &-reminder_1 {
    background-color: #ad2300;
  }

  &-payment_issue {
    background-color: #777;
  }

  &-not_paid {
    background-color: #f0ad4e;
  }

  &-paid {
    background-color: #5cb85c;
  }

  &-sent {
    background-color: #5bc0de;
  }

  &-accepted {
    background-color: #5cb85c;
  }

  &-received {
    background-color: #5cb85c;
  }

  &-processing {
    background-color: #86AC34;
  }

  &-UGL_confirmed {
    background-color: #f0ad4e;
  }

  &-UGL_tracking {
    background-color: #f0ad4e;
  }

  &-offer_accepted {
    background-color: #295DA6;
  }

  &-payment_issue {
    background-color: #d9534f
  }

  &-credit {
    background-color: black;
  }

  &-check {
    background-color: black;
  }

  &-canceled {
    background-color: #d9534f;
  }

  &-refunded {
    background-color: #d9534f;
  }
}

.vorkasse-holder {
  border-bottom: solid 2.5px #e51822 !important;
}

.mo-receipt {
  border: solid 3.5px grey;
  padding: 15px;
  position: relative;
}

.t-right {
  text-align: right;
}

.mo-title {
  margin-bottom: 30px;
  margin-top: 10px;
}

.mo-title h3 {
  font-weight: 600;
}

.mo-title-r {
  text-transform: uppercase;
  color: #e51822;
}

.mo-title-r h3 {
  color: #e51822;
}

.mo-row {
  margin-right: unset;
  margin-left: unset;
}

.mo-row .col {
  border: solid 2.5px #e51822;
  min-height: 60px;
  border-bottom: 0px;
}

.mo-receipt .col span {
  letter-spacing: 3px;
  font-size: 17px;
  font-weight: 600;
}

.col-b-p {
  background-color: #fce6e8;
}

.mo-row .field-title {
  margin-top: unset;
  margin-bottom: 5px;
  margin-left: -13px;
  color: #e51822;
  background-color: #fff;
  display: table;
  font-weight: 600;
}

.m-block {
  display: none;
}

.mo-row p {
  position: relative;
  top: -4px;
  font-size: 13px;
}

.mo-row h4,
.mo-row p {
  display: inline;
  font-weight: bold;
  color: #e51822;
}

.mo-euro {
  border: 4px solid #e51822 !important;
  border-bottom: 2px solid #e51822 !important;
}

.mo-euro h3 {
  font-weight: 800;
  color: #e51822;
}

.mo-footer .signature {
  border-bottom: solid 2.5px #e51822;
  margin-top: 10px;
  margin-left: 10%;
  width: auto;
  display: inline-flex;
  flex-direction: column;
}

.signature h5 {
  margin-bottom: 0px;
  display: flex;
  align-self: center;
  /* Make the flexbox items aligned vertically at the bottom */
  align-items: flex-end;
  flex-grow: 1;
  color: #e51822;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0px;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
}

.signature-field {
  background-color: #fff;
  min-height: 90px;
  min-width: 420px;
  margin-top: 15px;
}

.mo-order-ref {
  background-color: #fce6e8;
  min-height: 120px;
  float: right;
  margin-right: -15px;
}

.qr-area {
  position: absolute;
  bottom: 216px;
  right: 25px;
}

.mo-qr {
  height: 230px;
  width: 230px;
  padding: 2px;
  background-color: #fff;
  border: 4px solid #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-area .mo-qr .mo-qr-img {
  max-width: 300px !important;
  max-height: 300px !important;
  margin: 0;
  padding-left: unset;
  width: 100% !important;
}

.qr-area .mo-qr span {
  position: absolute;
  bottom: -3px;
  font-weight: 700;
  background-color: #fff;
  padding-left: 7px;
  padding-right: 7px;
  line-height: 15px;
}

@media(max-width:1200px) {
  .mo-row h4 {
    font-size: 14px;
  }

  .mo-row p {
    font-size: 11px;
  }

  .mo-title h3 {
    font-size: 20px;
  }

  .mo-row .col span {
    font-size: 15px;
  }

  .mo-euro {
    border: 3px solid #e51822 !important;
    border-bottom: 1px solid #e51822 !important;
  }

  .mo-euro h3 {
    top: 9px;
    position: relative;
    font-size: 18px;
  }

  .mo-footer .signature {
    margin-left: 2%;
  }

  .signature h5 {
    font-size: 14px;
  }

  .qr-area {
    position: absolute;
    bottom: 16px;
    right: 25px;
  }
}

@media(max-width:992px) {
  .qr-area {
    bottom: 2%;
    right: 2%;
  }

  .mo-qr {
    height: 180px;
    width: 180px;
  }

  .mo-row h4 {
    font-size: 10px;
  }

  .mo-row p {
    font-size: 8px;
  }


  .mo-row .col span {
    font-size: 14px;
    letter-spacing: 2px;
  }

  .mo-euro {
    padding-right: 0px;
    padding-left: 11px;
  }

  .mo-euro h3 {
    top: 9px;
    position: relative;
    font-size: 15px;
  }


  .signature-field {
    background-color: #fff;
    min-width: 295px;
    min-height: 66px;
  }

  .qr-area .mo-qr span {
    bottom: -5px;
  }


}

@media(max-width:698px) {
  .mo-footer .signature {
    margin-left: 0%;
    width: 184px;
  }

  .signature-field {
    min-width: 100px;
  }

  .signature h5 {
    font-size: 7px;
  }

  .mo-title h3 {
    font-size: 13px;
  }

  .mo-row .col {
    padding-right: 4px;

  }

  .mo-row p {
    font-size: 5px;
  }

  .mo-row .col span {
    font-size: 12px;
  }

  .mo-euro h3 {
    font-size: 10px;
  }

  .mo-qr {
    height: 100px;
    width: 100px;
  }

  .mo-euro {
    padding-left: 8px;
  }

  .m-none {
    display: none !important;
  }

  .m-block {
    display: block !important;
  }

  .qr-area {
    right: 3%;
  }

  .qr-area .mo-qr span {
    bottom: -2px;
    line-height: 7px;
    font-size: 6px;
  }
}

@media(max-width:376px) {
  .xm-none {
    display: none !important;
  }
}

.warehouse {
  color: $theme-color;
}

.fa-warehouse {
  font-size: 15px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
  color: $theme-color;
}

.fa-location-dot {
  width: 9px;
  color: $theme-color;
  margin-left: -2px;
  margin-bottom: -6px;
}

.pickup-title {
  margin-top: 5px;
}

.pickup-title span {
  padding: 0 0 0 4px;
  color: $theme-color;
}

.courier span {
  padding: 0 0 0 4px;
}

.product-details-my-order-mobile {
  margin-bottom: 20px;
  position: relative;

  .product-count-my-order {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $theme-color;
    color: white;
    padding: 5px 10px 5px 5px;
    border-radius: 0px 0px 100% 0px;
  }
}

.border-theme-color-2 {
  border: 2px solid $theme-color;
}


.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin: 0;
  }

  button {
    max-width: calc(50% - 15px);
  }

  @media #{$lg-layout} {
    display: block;

    button {
      margin-top: 10px;
      max-width: 100%;
    }
  }

  @media #{$md-layout} {
    display: block;

    button {
      margin-top: 10px;
      max-width: 100%;
    }
  }

  @media #{$xs-layout} {
    display: block;

    button {
      line-height: 1.4;
      padding: 10px 35px;
      margin-top: 15px;
      max-width: 100%;
    }
  }

  .ioons-button-disabled-color:hover {
    background-color: gray !important;
  }
}