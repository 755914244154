/*---- 18. Shop page  -------*/
.topbar-n .shop-select {
  @media #{$lg-layout} {
    width: 1px;
  }
}

.shop-top-bar-md-space {
  @media #{$md-layout} {
    margin-bottom: 7px;
  }
}

.shop-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media #{$xs-layout} {
    display: block;
    max-width: 48%;
  }

  @media #{$sm-layout} {
    display: flex;
  }

  .select-shoing-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$xs-layout} {
      display: block;
    }

    @media #{$sm-layout} {
      display: flex;
    }

    @media #{$md-layout} {
      width: 100%;
    }

    p {
      @media #{$md-layout} {
        min-width: 80px;
      }
    }

    .shop-select {
      @media #{$md-layout} {
        width: 100%;
      }

      select {
        font-size: 14px;
        font-weight: 450;
        min-width: 143px;
        padding: 5px;
        color: #606060;
        border: 1px solid #e6e6e6;

        @media #{$md-layout} {
          width: 100%;
        }
      }

      select::-webkit-scrollbar {
        width: 6px;
      }

      select::-webkit-scrollbar-track {
        background-color: $theme-gray-2;
      }

      select::-webkit-scrollbar-thumb {
        background-color: $theme-color;
      }

      select::-webkit-scrollbar-thumb:hover {
        background-color: $theme-color-hover;
      }
    }

    p {
      margin: 0 5px 0 10px;
      color: #606060;

      @media #{$xs-layout} {
        margin: 5px 0 10px;
      }

      @media #{$sm-layout} {
        margin: 0;
      }
    }
  }

  .shop-tab {

    a,
    button {
      font-size: 18px;

      margin-left: 20px;
      padding: 0;

      color: #606060;
      border: none;
      background: none;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        color: #fa6bff;
      }
    }
  }
}

.product_list_title {
  text-align: center;
  margin: 0 0 40px;

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 23px;
      margin: 0 0 20px;
    }
  }
}

.shop-list-wrap {
  .product-wrap {
    &:hover .hover-img {
      visibility: inherit;

      transform: none;

      opacity: inherit;
    }
  }

  .shop-list-content {
    @media #{$xs-layout} {
      margin-top: 20px;
    }

    @media #{$sm-layout} {
      margin-top: 0;
    }

    h3 {
      font-size: 24px;

      margin: 0;

      color: #010101;

      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 20px;
      }

      a {
        color: #010101;

        &:hover {
          color: $theme-color;
        }
      }
    }

    .product-list-price {
      margin: 13px 0 22px;

      @media #{$xs-layout} {
        margin: 13px 0 13px;
      }

      @media #{$md-layout} {
        margin: 13px 0 19px;
      }

      span {
        font-size: 24px;
        line-height: 1;

        display: inline-block;

        color: $theme-red;

        &.old {
          font-size: 18px;

          margin-left: 15px;

          text-decoration: line-through;

          color: #cfcfcf;
        }
      }
    }

    .rating-review {
      display: flex;
      align-items: center;

      .product-list-rating {
        i {
          font-size: 17px;

          margin: 0 3px 0;

          color: #5f5d5d;

          &.yellow {
            color: $bg-orange;
          }
        }
      }

      a {
        font-size: 15px;
        line-height: 1;

        position: relative;

        margin-left: 40px;

        color: #ffcf75;

        &:before {
          position: absolute;
          top: 0;
          left: -21px;

          width: 1px;
          height: 15px;

          content: "";

          background-color: #d1d1d1;
        }
      }
    }

    p {
      font-size: 15px;
      line-height: 28px;

      margin: 15px 0 40px;

      color: #8f8f8f;

      @media #{$xs-layout} {
        margin: 12px 0 20px;
      }

      @media #{$md-layout} {
        margin: 12px 0 20px;
      }

      @media #{$lg-layout} {
        margin: 15px 0 20px;
      }
    }

    .shop-list-btn {

      a,
      button {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        z-index: 1;

        display: inline-block;

        padding: 14px 32px 15px;

        text-transform: uppercase;

        color: #fff;
        border: 1px solid transparent;
        background-color: #343538;

        @media #{$xs-layout} {
          font-size: 13px;

          padding: 10px 20px 11px;
        }

        &:hover {
          border: 1px solid $theme-color;
        }
      }
    }
  }

  &:hover .product-wrap .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.page-item:last-child .page-link {
  margin-left: 0;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.sidebar-width {
  position: absolute;
  z-index: 9;
  width: 279px;
  height: 555px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: $bg-grey;
  padding: 0 20px;

  @media #{$lg-layout} {
    width: 297px;
    max-height: 554px;
  }

  @media #{$md-layout} {
    width: 346px;
    max-height: 458px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.sidebar-width-brands {
  height: 606px;

  @media #{$lg-layout} {
    max-height: 606px;
  }

  @media #{$md-layout} {
    max-height: 510px;
  }
}

.sidebar-width::-webkit-scrollbar {
  width: 6px;
}

.sidebar-width::-webkit-scrollbar-track {
  background-color: $theme-gray-2;
}

.sidebar-width::-webkit-scrollbar-thumb {
  background-color: $theme-color;
}

.sidebar-width::-webkit-scrollbar-thumb:hover {
  background-color: $theme-color-hover;
}

.topbar-n-paginator {
  min-height: 45px;
  margin-left: 25%;
  overflow: hidden;
  width: 100%;
  display: table;

  @media #{$lg-layout} {
    margin-left: 35%;
    margin-right: 10px;
  }

  @media #{$md-layout} {
    margin-left: 50%;
    overflow: auto;
  }

  @media #{$xs-layout} {
    margin-left: 0%;
    margin-bottom: 15px;
  }

  @media #{$xxs-layout} {
    margin-left: 0%;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.my-3-cat {
  @media #{$xs-layout} {
    margin-top: 0 !important;
    margin-bottom: 5px !important;
  }
}

.topbar-n {
  float: left;
  display: table-cell;
  vertical-align: middle;

  @media #{$xs-layout} {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.topbar-brands {
  display: flex;
  margin: 20px 0;

  @media #{$md-layout} {
    display: block;
    margin: 0 !important;
  }

  @media #{$xs-layout} {
    justify-content: space-between;

    p {
      margin: 0 5px 0 0 !important;
      font-size: .875rem;
    }

    margin: 0 !important;
  }
}

.n-paginator {
  float: right;
  display: table-cell;
  vertical-align: middle;

  @media #{$xs-layout} {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  @media #{$md-layout} {
    margin-left: -50%;
  }
}

.button-style {
  background-color: $theme-color; // Grey background
  color: #fff;
  width: auto; // Dynamic width based on content
  padding: 0 20px; // Horizontal padding adjusted for aesthetics
  font-size: 14px; // Appropriate font size
  line-height: normal; // Normal line height
  white-space: nowrap; // Prevent text from wrapping
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; // Fixed height for uniform appearance
  font-weight: bold; // Bold font weight
  text-transform: uppercase; // Text in uppercase
  border: none; // No borders
  cursor: pointer; // Pointer on hover to indicate clickable
  transition: background-color 0.3s, color 0.3s; // Smooth transition for color and background
  border-radius: 100px; // Increased rounded edges to match the pill shape
}


.button-style:hover {
  background-color: $theme-color-hover; // Teal background on hover
  color: #fff; // White text on hover
}

.button-style.active {
  background-color: $theme-color; // Teal background for active state
  color: #fff; // White text for active state
  box-shadow: none; // No box shadow in active state
}

.rating-container {
  display: flex;
  justify-content: flex-start;
  /* Centers the stars horizontally */
  align-items: flex-start;
  /* Aligns the stars vertically */
  padding: 10px 0;
  /* Adds top and bottom padding around the stars */
  margin: 5px 0;
  /* Optionally add margin if more space is needed */
}

.rating-stars {
  cursor: pointer;
  font-size: 42px;
  /* Larger stars as per your preference */
  color: gray;
  /* Default color */
  margin: 0 0px;
  /* Horizontal spacing between stars */
}

.rating-stars.active {
  color: orange;
  /* Active color when a star is clicked */
}

.form-warning {
  color: red;
  margin-top: 10px;
}

.form-success {
  color: green;
  margin-top: 10px;
}

.pro-pagination-style {
  @media #{$xs-layout} {
    &.mt-30 {
      margin-top: 10px;
    }
  }

  ul {
    li {
      display: inline-block;

      margin: 0 4px;

      a,
      button {
        font-size: 20px;
        line-height: 43px;
        font-weight: 450;
        display: inline-block;

        width: 43px;
        height: 43px;
        border-radius: 16px !important;
        padding: 0;
        margin: 0 3px;
        text-align: center;

        color: black;
        border: none;
        //border-radius: 100% !important;
        background: $bg-grey;
        //box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);

        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      a.active {
        color: #fff;
        background-color: $theme-color;
        box-shadow: none;

        &:hover {
          background-color: #333;
        }
      }

      a.prev,
      a.next {
        font-size: 17px;

        color: $theme-color;
        background-color: #f6f6f6;
        box-shadow: none;

        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      &.page-item {
        &.active {
          .page-link {
            //border-color: $theme-color;
            background-color: $theme-color;
            //box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .page-link {
          //box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media #{$xs-layout} {
  .shop-area.pb-100 {
    //padding-bottom: 45px;
  }
}

.list2-col-style {
  .shop-list-wrap {
    .shop-list-content {
      @media #{$xl-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }

        .product-list-price {
          margin: 13px 0 10px;
        }

        p {
          margin: 6px 0 20px;
        }

        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 24px 11px;
          }
        }
      }

      @media #{$lg-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }

        .product-list-price {
          margin: 13px 0 10px;
        }

        p {
          margin: 6px 0 20px;
        }

        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 20px 11px;
          }
        }
      }
    }
  }
}

.grid-sizer {
  width: 25%;
}

.shop-bottom-area {
  width: 100%;

  .col-xl-4 {
    transition: 0.5s;
  }

  .grid {
    .shop-list-wrap {
      display: none;
    }

    &.two-column {
      .col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;
      }
    }

    &.four-column {
      .col-xl-4 {
        flex: 1 0 25%;

        max-width: 25%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33%;

          max-width: 33%;
        }

        @media #{$md-layout} {
          flex: 1 0 50%;

          max-width: 50%;
        }

        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }

    .list {
      .product-wrap {
        display: none;
      }

      &.two-column {
        &>.col-xl-4 {
          flex: 1 0 50%;

          max-width: 50%;

          transition: 0.5s;

          @media #{$lg-layout} {
            flex: 1 0 100%;

            max-width: 100%;
          }

          @media #{$md-layout} {
            flex: 1 0 100%;

            max-width: 100%;
          }

          @media #{$xs-layout} {
            flex: 1 0 100%;

            max-width: 100%;
          }
        }
      }

      &.four-column {
        &>.col-xl-4 {
          flex: 1 0 25%;

          max-width: 25%;

          transition: 0.5s;

          @media #{$lg-layout} {
            flex: 1 0 33%;

            max-width: 33%;
          }

          @media #{$md-layout} {
            flex: 1 0 50%;

            max-width: 50%;
          }

          @media #{$xs-layout} {
            flex: 1 0 100%;

            max-width: 100%;
          }
        }
      }

      &>.col-xl-4 {
        flex: 1 0 100%;

        max-width: 100%;

        transition: 0.5s;
      }
    }
  }

  .shop-list-wishlist,
  .shop-list-compare {
    button {
      font-size: 20px;

      padding: 0;

      border: none;
      background: none;

      &:hover,
      &.active {
        color: $theme-color;
      }
    }
  }

  .shop-area {
    .container-fluid {
      padding: 0 70px;

      @media #{$xl-layout} {
        padding: 0 15px;
      }

      @media #{$lg-layout} {
        padding: 0 30px;
      }

      @media #{$md-layout} {
        padding: 0 40px;
      }

      @media #{$xs-layout} {
        padding: 0 15px;
      }
    }
  }
}

.no-products {
  font-size: 1rem;
  height: 484.67px; //440px + 44.67px (the exact height of breadcrumb) for simetry
  text-align: center;
  margin: auto;

  @media #{$lg-layout} {}

  @media #{$md-layout} {
    margin-left: auto;
    margin-right: 0;
    width: 50%;
    padding-top: 70px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}

.loading-space {
  height: 484.67px; //440px + 44.67px (the exact height of breadcrumb) for simetry
  margin: auto;

  p {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 15px;
  }

  img {
    margin: auto;
    display: block;
    width: 147px;
  }

  @media #{$lg-layout} {}

  @media #{$md-layout} {
    width: 50%;
    padding-top: 70px;
    margin-right: 0;
  }

  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}

.loading-space-modal {
  margin: auto;

  p {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 15px;
  }

  img {
    margin: auto;
    display: block;
    width: 147px;
  }

  @media #{$lg-layout} {}

  @media #{$md-layout} {
    width: 50%;
    margin-left: auto;
    margin-right: 0;
    padding-top: 70px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 70px;
  }
}

.searched-word-n-nr-of-results {
  min-height: 45px;
  margin-left: calc(25% + 7.5px);
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  color: #606060;

  @media #{$lg-layout} {
    margin-left: 35%;
    margin-right: 10px;
  }

  @media #{$md-layout} {
    margin-left: 0%;
  }

  @media #{$xs-layout} {
    margin-left: 0%;
  }

  @media #{$xxs-layout} {
    margin-left: 0%;
    margin-top: 5px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .searched-word-n {
    flex: 1;

    @media #{$xs-layout} {
      width: 100%;
      text-align: center;
      flex: none;
      display: block;
    }
  }

  .n-nr-of-results {
    flex: 0 0 auto;

    @media #{$xs-layout} {
      width: 100%;
      text-align: center;
      flex: none;
      display: block;
    }
  }

  .search-page-value {
    background-color: $theme-color;
    color: white;
    padding: 3px 12px;
    border-radius: 50px;
    vertical-align: middle;
    margin-left: 3px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
  }
}

.overlay-paypal::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.overlay-paypal::after {
  content: "";
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid $theme-color;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 10000;

  @media #{$xs-layout} {
    width: 48px;
    height: 48px;
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}