.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, rgb(252, 207, 49) 10%, rgb(245, 85, 85) 100%);
}

.error-content {
    text-align: center;
    position: relative;
    width: 40%;
    height: 50%;
    margin-bottom: 10%;
}

.sorry-message-title {
    margin: 0.5em 0 0.25em 0;
}

.screw {
    animation: rotate 5s linear infinite;
    height: 100%;
    color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10em;
    text-shadow: -2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.parent {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.child {
    position: absolute;
    width: 100%;
}

.child-behind {
    // background-color: blue;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.child-front {
    background-color: $theme-color-hover;
    width: 75%;
    height: 65%;
    bottom: 15%;
    border-radius: 10px 10px 0 0;

    .screen-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        //background-color: yellow;
        border-radius: 10px 10px 0 0;
        border: 2px solid black;
        border-bottom: 0;

        .screen {
            background-color: white;
            width: 95%;
            height: 95%;
            position: absolute;
            bottom: 0;

            .window-app-wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100%;
                border: 2px solid black;
                border-bottom: 0;

                .window-bar {
                    position: absolute;
                    background-color: white;
                    border-radius: 10px 10px 0 0;
                    width: 85%;
                    height: 10%;
                    top: 5%;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    padding-left: 10px;
                    border: 2px solid black;
                    border-bottom: none;

                    svg {
                        margin-right: 5px;
                        max-height: 90%;
                    }
                }

                .window-app {
                    position: absolute;
                    background-color: #ffcf64;
                    border-radius: 0 0 10px 10px;
                    width: 85%;
                    height: 80%;
                    bottom: 5%;
                    border: 2px solid black;

                    .in-app-logo {
                        height: 40%;
                        padding: 20px;

                        img,
                        svg {
                            height: 100%;
                            max-width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: left;
                        }

                        svg {
                            color: $theme-color;
                        }
                    }

                    .in-app-contact {
                        height: 60%;
                        padding: 0 20px 20px 20px;
                        display: flex;
                        flex-direction: column;

                        .contact-row {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: left;
                            line-height: 1em;

                            i {
                                width: 30px;
                            }

                            .schedule {
                                color: white;
                                background-color: $theme-color;
                                margin-left: 5px;
                                padding: 2px 8px;
                                border-radius: 5px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

}

.child-front-down {
    background-color: $theme-color;
    width: 75%;
    height: 15%;
    bottom: 0%;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;

    .fa-circle {
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 1024px) {
    .error-content {
        width: 80%;
    }

    .parent {
        height: 75%;
    }

    .child-front {
        width: 100%
    }

    .child-front-down {
        width: 100%
    }
}

@media only screen and (max-width: 600px) {
    .child-front .screen-wrapper .screen .window-app-wrapper .window-app .in-app-logo {
        height: 30%;
        padding: 10px;
    }

    .child-front .screen-wrapper .screen .window-app-wrapper .window-app .in-app-contact {
        height: 70%;
        padding: 0 10px 10px 10px;

        .contact-row:first-child {
            flex: 2;
            white-space: normal;
        }
    }
}