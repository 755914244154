.slick-next:before {
  font-family: 'slick';
  font-size: 35px;
  line-height: 1;
  opacity: .75;
  color: $theme-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'Arial', sans-serif;
  font-size: 35px;
  line-height: 1;
  color: $theme-color;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.slick-arrow {
  position: absolute;
  z-index: 10;
  top: -webkit-calc(55% - 25px);
  top: calc(55% - 25px);
  height: 50px;
  left: auto;

}

.slick-dots li button {
  width: 15px;
  height: 7.5px;
}





.serie-box {


  position: relative;
  width: 100%;

  margin-left: 2.5% auto;
  margin-right: 2.5% auto;
  margin-inline-start: calc(0.1rem * -1);

}





.serie {

  position: relative;
  width: 100%;
  max-width: 100px;
  padding-left: 1%;
  padding-right: 1%;
  margin-right: 5px;
  margin-left: 5px;

}



.slick-slide>div {

  width: 99%;
  overflow-x: hidden;
  diplay: flex;
  margin-top: 10px;

  height: auto;

  padding: 5px;
  box-shadow:
    1rem 0 #fff,
    -1rem 0 0 #fff;



}

.play {
  position: absolute;
  top: 80%;
  left: 50%;
  border-radius: 60px;
  border: none;
  background: white;
  color: black;
  line-height: 1.3333333333;
  width: 10%;
  font-weight: bold;
  text-align: center;
  font-size: .75rem
}

.image-content {
  position: absolute;
  top: 20%;
  left: 100%;
  font-family: Poppins, sans serif;
  font-style: normal;
  font-weight: 400;
  color: white;
  font-size: 1.5rem;
  line-height: 1.5;
  word-break: word-break;
  width: 100%
}


.product-banner {
  color: white;
}


.bannertext {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  background-color: #a39b9c75;
  padding: 5px 20px;
  text-align: center;
  line-height: 1.5;
  font-family: Avenir LT W85 Heavy, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: white;
  font-weight: 700;
  letter-spacing: 0.025em;
  font-size: 28px;
  position: absolute;
  bottom: 8px;
  left: 16px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .bannertext {
    padding: 5px 10px;
    font-size: 18px;
    line-height: 1;
  }
}

.ibanner {
  background-color: fade(#FFFFFF, 10%);

}


.brand-block {
  margin-left: 80px;
  display: grid;
  grid-template-columns: 1032px auto auto;
  gap: 12px;
  align-items: flex-start;
}

.headline-brand {
  font-family: Avenir LT W85 Heavy, Avenir LT W01_85 Heavy1475544, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.25px;
  margin: 10;
  line-height: 20px;

}

.middlebutton {
  text-align: center;
}



/*
.slick-slider:hover .slick-arrow{
  transition:all 1s ease;
}*/