.topaktion {}

.cardH4 {
  color: white;
  font-weight: 600;
  width: 100%;
  bottom: 0;
}

.fs-small {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin: 0;
}

.x {
  font-family: Raleway-SemiBold;
  font-size: 13px;
  color: red;
  letter-spacing: 1px;
  line-height: 15px;
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
}

.play {
  position: absolute;
  top: 80%;
  left: 30%;
  border-radius: 60px;
  border: none;
  background: white;
  color: black;
  line-height: 1.3333333333;
  width: 220%;
  font-weight: bold;
  text-align: center;
  font-size: .75rem
}

.play:focus {
  background-color: white;
}

topcategories .data {

  color: pink;

}


/*
.slick-slider:hover .slick-arrow{
    transition:all 1s ease;
}*/

.custom-card {
  position: relative;
}

.card-title-custom {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 1.5em;
}

@media (max-width: 1280px) {
  .card-title-custom {
    min-height: 2em;
    font-size: 1.2em
  }
}

.card-body-custom {
  padding: 10px;
  clip-path: polygon(0 0, 30% 0, 35% 25%, 100% 25%, 100% 100%, 0 100%);
  position: absolute;
  width: 100%;
  bottom: 0;
  background: linear-gradient(to right, $theme-color 50%, $theme-color-hover 100%) !important;
}

@media (max-width: 1280px) {
  .card-body-custom {
    position: relative;
    z-index: 1;
    margin-top: -15%;
  }
}

.card-button {
  border-radius: 64px;
  width: 30%;
  background-color: white;
  color: black;
  border-color: $theme-color;
}

.card-button-custom {
  position: relative;
  overflow: hidden;
}

.card-button-custom::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, rgba(177, 172, 168, 0.5) 50%, transparent 100%);
}

.card-button-custom:hover::before {
  left: 100%;
  transition: left 0.5s;
}

.card-button-custom svg path {
  transition: fill 0.5s ease;
}

.card-button-custom:hover svg path {
  animation: bounce 1s ease;
  fill: $theme-color;
}

@keyframes bounce {
  0% {
    transform: translateX(-20px);
  }

  80% {
    transform: translateX(0px);
  }
}