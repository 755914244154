/*------ 24. login register Page  --------*/

.login-register-wrapper {
  .login-register-tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @media #{$xs-layout} {
      display: block;
      text-align: center;
    }

    .nav-item {
      position: relative;

      a {
        background: none;
        padding: 0;

        h4 {
          font-size: 25px;
          font-weight: 700;
          margin: 0 20px;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
          color: #313131;
        }

        &:last-child::before {
          display: none;
        }
      }

      a.active h4 {
        color: $theme-color;
      }

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: '';
        height: 18px;
        margin: 0 auto;
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;

        @media #{$xs-layout} {
          display: none;
        }
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .feedback-vat {
    display: flex;
    position: relative;

    .fa-check {
      color: $theme-color;
    }

    .fa-warning {
      color: rgb(164, 155, 156);
    }
  }

  .fa-vat {
    right: 6px;
    top: 16px;
    position: absolute;
  }

  .response-login-wrapper {
    margin: 20px auto 0px;
    text-align: center;
  }

  .login-center-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form-container {
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 400px;
    padding: 20px;

    @media #{$lg-layout} {
      padding: 80px 50px;
    }

    @media #{$md-layout} {
      padding: 40px 15px;
    }

    @media #{$sm-layout} {
      padding: 40px 15px;
    }

    @media #{$xxs-layout} {
      padding: 40px 15px;
    }

    .login-register-form {


      // toggle button
      .customer-choice {
        display: grid;
        grid-template-columns: $login-grid-template;
        margin: 20px 0;
        font-size: 18px;
        user-select: none;


        .switch {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 17px;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          cursor: pointer;
        }

        .slider:before {
          position: absolute;
          content: '';
          height: 13px;
          width: 13px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        input+.slider {
          background-color: $theme-color;
        }

        input:checked+.slider {
          background-color: $theme-color;
        }

        input:focus+.slider {
          box-shadow: 0 0 1px slategray;
        }

        input:checked+.slider:before {
          -webkit-transform: translateX(13px);
          -ms-transform: translateX(13px);
          transform: translateX(13px);
        }

        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }

        .toggle-btn {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        >label {
          transition: 0.5s;
          font-weight: 600;
          text-align: center;
          cursor: pointer;
        }

        .selected-choice {
          color: $theme-color;
        }

        .unselected-choice {
          color: $theme-gray-2;
        }
      }

      form {

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset;
        }

        .select-dropdown {
          border-radius: 7px;
          margin-bottom: 30px;
        }

        .select-dropdown::-webkit-scrollbar {
          width: 6px;
        }
    
        .select-dropdown::-webkit-scrollbar-track {
          background-color: $theme-gray-2;
        }
    
        .select-dropdown::-webkit-scrollbar-thumb {
          background-color: $theme-color;
        }
    
        .select-dropdown::-webkit-scrollbar-thumb:hover {
          background-color: $theme-color-hover;
        }

        .input-wrapper {
          position: relative;

          input {
            color: black;
          }
        }

        .loading-overlay {
          position: absolute;
          margin-top: 11px;
          margin-left: 15px;

          div>div {
            top: 7px !important;
          }
        }

        .loading-overlay>* {
          margin: 0;
        }

        input {
          background-color: transparent;
          border: 1px solid #a39b9c;
          border-radius: 7px;
          color: #333;
          font-size: 14px;
          height: 45px;
          margin-bottom: 30px;
          padding: 0 15px;
          transition: 0.3s;
        }

        input:focus {
          border: 2px solid $theme-color;
          box-shadow: 0 0 1px 1px $theme-color;
        }

        input[type='checkbox'] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }

        .form-group-custom-row {
          position: relative;
        }

        .custom-placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 18px;
          left: 15px;
          transition: 0.3s;
          pointer-events: none;
          z-index: 15;
          height: 15px;
          border-radius: 5px;
          color: #333;
        }

        #password-visible {
          position: absolute;
          top: 10px;
          right: 15px;
          transition: 0.5s;
          font-size: 17px;
          cursor: pointer;
        }

        #password-visible:hover {
          color: $theme-color-hover;
        }

        .button-box {
          .login-toggle-btn {
            margin-top: 10px;

            label {
              color: #242424;
              font-size: 15px;
              font-weight: 400;

              @media #{$xs-layout} {
                font-size: 14px;
              }
            }

            a {
              color: #242424;
              float: right;
              font-size: 15px;

              @media #{$xs-layout} {
                font-size: 14px;
              }

              transition: all 0.3s ease 0s;

              &:hover {
                color: $theme-color;
              }
            }
          }

          button {
            background-color: $theme-color;
            border: medium none;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 23px 80px 23px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;
            width: 100%;
            border-radius: 20px;

            @media #{$xs-layout} {
              padding: 23px 12px 23px;
            }

            @media #{$md-layout} {
              padding: 23px 22px 23px;
            }

            &:hover {
              background-color: $theme-color-hover;
            }

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;

              &:hover {
                background-color: $theme-color;
              }
            }
          }
        }
      }
    }
  }
}

.input-info {
  line-height: 1.5;
  font-size: 12px;
  margin: 5px 0 13px;
  padding: 0px 15px;
  color: $theme-red;
  transition: opacity 0.3s ease;
  opacity: 0;
  /* Initially hide the text */
}

.input-info-checkout {
  color: #808080;
  line-height: 1.5;
  font-size: 12px;
  margin: 5px 0 0;
}

.input-info-account {
  min-height: 28px;
  color: #808080;
  line-height: 1.5;
  font-size: 12px;
  text-align: left;
  padding: 0px 0px 10px 40px;
}

.input-info-account-wrapper {
  min-height: 28px;
}

@media screen and (max-width: 767px) {
  .input-info-account-wrapper {
    min-height: 46px;
  }
}

@media screen and (max-width: 400px) {
  .input-info-account-wrapper {
    min-height: 64px;
  }
}

@media (min-width: 768px) {
  .input-info-checkout {
    width: 670px;
  }
}

.login-register-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.form-check-input[type='radio'] {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.25em;
}

.form-check-label {
  display: flex;
  align-items: center;
}

.form-group-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
}

.form-group-check label {
  cursor: pointer;
  width: calc(100% - 24px);
}

.form-group-check a {
  text-decoration: underline;
}

.form-group-checkout {
  margin-left: 24px;
}

.form-group-checkout a {
  text-decoration: underline;
}

.form-group-checkout label {
  cursor: pointer;
}

.form-group-check .custom-control-input:checked~.custom-control-label::before {
  border-color: $theme-color !important;
  background-color: $theme-color !important;
}

.form-group-checkout .custom-control-input:checked~.custom-control-label::before {
  border-color: $theme-color !important;
  background-color: $theme-color !important;
}

.login-register-form #customer,
.login-register-form #company {
  margin-bottom: 0px;
}

.register-forgot-password {
  position: absolute;
  bottom: -24px;
  right: 0;
}