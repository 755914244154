/*------- 21. Checkout page  -----------*/

.billing-info-wrap {
  h3 {
    font-size: 18px;
    font-weight: 500;

    margin: 0;
    line-height: 1.5;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;

    @media #{$xs-layout} {
      margin: 0;
    }
  }

  .choose-address {
    width: 84%;
    margin: 3px 0 5px;

    select {
      color: white;
      cursor: pointer;

      option {
        background-color: $theme-gray-2;
      }
    }

    select::-webkit-scrollbar {
      width: 4px;
    }

    select::-webkit-scrollbar-track {
      background-color: $theme-gray-2;
    }

    select::-webkit-scrollbar-thumb {
      background-color: white;
    }

    select::-webkit-scrollbar-thumb:hover {
      background-color: white;
    }

    @media #{$xs-layout} {
      width: 90%;
      margin: 0 0 10px;
    }
  }

  .left-billing {
    padding: 12px 0 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(44% - 35px);

    @media #{$xs-layout} {
      width: 95%;
      margin: 0 auto;
      padding: 12px 0 4px;
      align-items: center;

      h3 {
        padding: 0 0 8px;
      }
    }
  }

  .right-billing {
    padding: 12px 15px 12px 0;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(56% - 30px);

    @media #{$xs-layout} {
      width: 90%;
      padding: 6px 0 17px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
  }

  .billing-info,
  .billing-select {
    .two-spaced-labels-ready {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrong-input-data {
        color: $theme-red;
      }
    }

    label {
      margin: 0 0 7px;

      color: #000;
    }

    input {
      font-size: 14px;

      padding-right: 10px;
      padding-left: 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      background: #ffffff;
    }

    input:disabled {
      cursor: not-allowed;
      background: #f6f6f6;
    }

    input.billing-address {
      margin-bottom: 10px;
    }

    input[type="text"]:not([readonly]) {
      cursor: text;
    }

    input[type="text"][readonly] {
      cursor: not-allowed;
      background: #f6f6f6;
    }
  }

  .billing-select {
    select {
      font-size: 14px;

      height: 45px;
      padding: 2px 20px;

      background: #ffffff;

      cursor: pointer;

      color: #333;
      border: 1px solid #e6e6e6;

      -webkit-appearance: none;
      -moz-appearance: none;
    }

    select:disabled {
      background: #f6f6f6;
    }

    select:read-only {
      background: #f6f6f6;
    }
  }

  .custom-form-select-checkout:disabled {
    background: #f6f6f6;
  }

  .checkout-account {
    display: flex;
    align-items: center;

    input {
      display: inline-block;
      float: left;

      width: 10px;
      height: 10px;

      border: 1px solid #9fa0a2;
    }

    span {
      font-weight: 400;

      margin: 0 0 0 12px;

      color: #333;
    }
  }

  .checkout-account-toggle {
    input {
      font-size: 14px;

      margin: 0 0 20px;
      padding-right: 10px;
      padding-left: 20px;

      color: #333;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }

    button.checkout-btn {
      font-weight: 500;

      z-index: 9;

      padding: 10px 30px;

      cursor: pointer;
      text-transform: uppercase;

      color: #fff;
      border: medium none;
      border-radius: 50px;
      background-color: $theme-color;

      &:hover {
        background-color: #333;
      }
    }
  }

  .additional-info-wrap {
    h4 {
      font-size: 16px;
      font-weight: 500;
    }

    .notes-header {
      background-color: $theme-gray-2;
      color: white;
      padding: 12px 0 12px 20px;
      border-radius: 20px;
    }

    @media only screen and (max-width: 768px) {
      .notes-header {
        justify-content: center;
        height: 100%;
      }
    }

    .additional-info {
      label {
        font-size: 14px;

        margin: 0 0 7px;

        color: #333;
      }

      textarea {
        font-size: 14px;

        height: 138px;
        padding: 17px 20px;

        color: #333;
        border: 1px solid #e6e6e6;
        background: #ffffff;
      }

      textarea:disabled {
        cursor: not-allowed;
        background: #f6f6f6;
      }
    }
  }

  .different-address {
    display: none;
  }
}

.ioons-button-border {
  border-radius: 100px;
  background-color: $theme-color !important;
  border: 1px solid #fff;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  width: calc(50% - 2px);
  text-align: center;
  padding: 12px 0 13px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:hover {
    background: $theme-color-hover !important;
    color: #fff;
  }

  &:active {
    background: $theme-color !important;
  }

  &:focus {
    background: $theme-color-hover !important;
  }

  &:visited {
    background: $theme-color !important;
    color: #fff;
  }

  &.cancel {
    background-color: $theme-red !important;

    &:hover,
    &:focus,
    &:visited {
      background-color: darken($danger, 15%);
    }
  }

  &:disabled {
    background-color: $theme-color !important;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.right-no-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.left-no-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#billing-box {
  display: none;
}

// toggle button
.customer-choice-checkout {
  display: grid;
  grid-template-columns: 42% 16% 42%;
  margin: 10px 0;
  font-size: 18px;
  user-select: none;
  align-items: center;

  @media #{$xs-layout} {
    font-size: 16px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input+.slider {
    background-color: $theme-color;
  }

  input:checked+.slider {
    background-color: $theme-color;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px slategray;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >label {
    transition: 0.5s;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }

  .selected-choice {
    color: $theme-color;
  }

  .unselected-choice {
    color: $theme-gray-2;
  }
}

.customer-profile-checkout {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;

  @media #{$xs-layout} {
    justify-content: center !important;
    margin: 20px auto !important;
  }
}

.shadow-form {

  input:focus,
  select:focus {
    border-color: $theme-color-hover;
    box-shadow: 0 0 1px 1px $theme-color-hover;
  }

  .choose-address {

    input:focus,
    select:focus {
      border-color: none;
      box-shadow: none;
    }

    select:disabled {
      cursor: not-allowed;
    }
  }
}

.customer-profile-checkout #choice-private {
  text-align: left;
  padding-right: 20%;
  white-space: nowrap;

  @media #{$xs-layout} {
    padding-right: 10px !important;
  }
}

.customer-profile-checkout #choice-company {
  text-align: right;
  padding-left: 20%;
  white-space: nowrap;

  @media #{$xs-layout} {
    padding-left: 10px !important;
  }
}

.feedback-vat {
  display: flex;
  position: relative;

  .fa-check {
    color: $theme-color;
  }

  .fa-warning {
    color: rgb(164, 155, 156);
  }
}

.fa-vat {
  right: 6px;
  top: 16px;
  position: absolute;
}

.recover-password {
  text-decoration: underline;
  color: $theme-red;
}

.recover-password:hover {
  text-decoration: underline;
  color: $theme-red;
}

.your-order-area {
  @media #{$md-layout} {
    margin-top: 30px;
  }

  @media #{$xs-layout} {
    margin-top: 30px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: #000;
  }

  .your-order-wrap {
    min-height: 150px;
    padding: 38px 45px;

    background: #f6f6f6;

    @media #{$lg-layout} {
      padding: 30px 20px 36px;
    }

    @media #{$xs-layout} {
      padding: 30px 20px 36px;
    }

    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;

          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;
          }
        }
      }

      .your-order-middle {
        margin: 29px 0 0;
        padding: 18px 0;

        border-top: 1px solid #dee0e4;
        border-bottom: 1px solid #dee0e4;

        ul {
          li {
            .title-n-subtotal {
              display: flex;
              justify-content: space-between;

              .order-middle-left {
                max-width: 70%;
              }

              .undeliverable {
                opacity: 0.75;
                text-decoration: line-through;
              }
            }

            .count-n-price {
              margin: 0 0 10px;
              color: $theme-red;
            }
          }
        }
      }

      .your-order-bottom-coupon {
        padding-top: 18px;
        border-top: 1px solid #dee0e4;
      }

      .your-order-bottom {
        padding: 18px 0;
        border-bottom: 1px solid #dee0e4;

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            font-size: 14px;
            font-weight: 400;
            list-style: none;
          }

          li.your-order-shipping {
            font-weight: 400;
            color: #212121;
          }

          span {
            font-size: 12px;
            margin-left: 5px;
          }

          .loading-ellipsis {

            .bkIMJn div,
            .eTxprY div,
            .hPEmbd div {
              background: black !important;
            }

            div>div {
              top: 7px !important;
            }
          }
        }

        ul.no-vat {
          margin-top: 9px;
        }
      }

      .your-order-bottom-bottom {
        margin-top: 18px;

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            font-size: 14px;
            font-weight: 400;
            list-style: none;
          }

          li.your-order-shipping {
            font-weight: 400;
            color: #212121;
          }

          .loading-ellipsis {

            .bkIMJn div,
            .eTxprY div,
            .hPEmbd div {
              background: black !important;
            }

            div>div {
              top: 7px !important;
            }
          }
        }

        .coupon {
          background-color: lightgray;
          font-size: 12px;
          float: none;
          vertical-align: middle;
          padding: 0 5px;
          border-radius: 5px;
        }
      }

      .your-order-total {
        margin: 18px 0 29px;
        padding: 18px 0;

        border-top: 1px solid #dee0e4;
        border-bottom: 1px solid #dee0e4;

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: #212121;
          }

          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $theme-color;
          }
        }

        .loading-ellipsis {
          div>div {
            top: 7px !important;
          }
        }
      }
    }
  }

  .order-methods {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }

  .order-methods label {
    margin-right: 0 !important;
    cursor: pointer;
  }

  .delivery-box {
    margin-bottom: 0.5rem;
  }

  .delivery-box label {
    margin-bottom: 0 !important;
  }

  .warehouse-label-del {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    display: flex !important;
    align-items: center !important;
    padding-left: 6px;
  }

  .del-warehouses {
    margin-left: calc(22% + 3px);
  }

  .del-warehouses .css-1hbvpl3-MuiSvgIcon-root {
    width: 0.84em;
    height: 0.84em;
  }

  .del-warehouses .MuiSvgIcon-root {
    width: 0.84em;
    height: 0.84em;
  }

  .del-warehouse {
    margin-bottom: 5px;
  }

  .del-warehouse .MuiFormControlLabel-asterisk {
    display: none;
  }

  .your-order-warning {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    color: #ff0000;
    text-align: center;

    @media #{$lg-layout} {
      padding: 18px 10px 18px !important;
      font-size: 13px;
    }

    @media #{$xs-layout} {
      padding: 18px 10px 18px !important;
      font-size: 13px;
    }

    .fa-warning {
      display: block;
      font-size: 20px;
      padding: 0 0 5px;
    }

    .ioons-button-border {
      width: auto !important;
      padding: 12px 20px !important;
      margin: 10px auto 0;
    }
  }

  .payment-accordion {
    margin: 0 0 16px;

    &:last-child {
      margin: 0 0 0;
    }

    h4 {
      font-size: 16px;

      margin: 0;

      color: #212121;

      a {
        position: relative;

        display: block;

        color: #212121;

        &:hover {
          color: $theme-color;
        }
      }
    }

    .panel-body {
      padding: 5px 0 0 0;

      p {
        font-size: 14px;

        padding: 0 0 0 10px;

        color: #333;
      }
    }
  }

  .place-order>a,
  .place-order>button {
    font-weight: bold;
    line-height: 1;

    z-index: 9;

    display: block;

    width: 100%;
    padding: 23px 80px 23px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    border: none;
    border-radius: 50px;
    background: none;
    background-color: $theme-color;

    @media #{$xs-layout} {
      padding: 23px 12px 23px;
    }

    @media #{$md-layout} {
      padding: 23px 22px 23px;
    }

    &:hover {
      background-color: $theme-color-hover;
    }

    &.btn-hover-gray {
      background-color: $theme-brand-btn;

      &:hover {
        background-color: $theme-breadcrumb;
      }
    }
  }
}

.checkout-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.exceptional-borders {
  position: relative;
}

.exceptional-borders::after {
  content: "";
  position: absolute;
  bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
  height: 10px;
  border-left: 2px solid grey;
  border-right: 2px solid grey;
  z-index: -1;
}

.exceptional-background {
  position: relative;
  background-color: $theme-gray-2;
  border-radius: 20px;
  margin: 0;
}

.exceptional-background::before {
  content: "";
  position: absolute;
  bottom: 0;
  // margin-left: 15px;
  // margin-right: 15px;
  /* Adjust the left position to control the starting point of the red background */
  // width: calc(100% - 30px);
  height: 100%;
  // background-color: $theme-gray-2;
  // border-radius: 20px;
  z-index: -2;
}

#choice-same-delivery {
  padding: 0 10px;
}

#choice-new-invoice {
  padding: 0 10px;
}

.undeliverable-modal .modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 90%;
  margin: 0;

  .undeliverable-modal-footer {
    display: flex;
    justify-content: flex-start;

    .return-button {
      color: $theme-gray-2;
      background: none;
      border: none;

      &:hover {
        color: $hover-gray;
      }
    }

    .proceed-button {
      position: absolute;
      right: 15px;
    }
  }
}

@media (max-width: 768px) {
  .undeliverable-modal .modal-dialog {
    width: 90%;
  }
}

.undeliverable-overlay-open .undeliverable-modal {
  position: relative;
  z-index: 9998;
}

.undeliverable-overlay-open::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9997;
}

[id^="zoid-paypal-buttons-uid_"]>iframe.component-frame {
  z-index: 98 !important;
}