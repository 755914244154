/*------- 14. Brand logo style  -------*/
.container-logos {
  padding: 0px 30px;
  margin: auto;

  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

.brand-logo-active,
.brand-logo-active-2 {
  position: relative;
  overflow: hidden;

  .gradient-overlay {
    display: flex;
    mask-image: linear-gradient(to right, transparent 0%, #000 33.3%, #000 66.66%, transparent 100%);
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }

  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 136px;
      filter: grayscale(100%);
      transition: all 0.5s ease 0s;
    }

    &:hover img {
      filter: grayscale(0%);
    }
  }

  .brand-logo-active:hover .single-brand-logo img {
    filter: grayscale(0%);
  }
}

.brand-logo-wrap {
  padding: 100px 90px;

  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}