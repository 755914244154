/*------ 22. contact Page  ------*/

.contact-map {
  position: relative;

  height: 560px;

  @media #{$xs-layout} {
    height: 400px;
  }
}

.contact-info-wrap {
  padding: 50px 70px 116px 90px;
  height: 100%;
  background-color: #f3f3f3;

  @media #{$lg-layout} {
    padding: 50px 20px 116px 40px;
  }

  @media #{$md-layout} {
    padding: 50px 20px 116px 30px;
  }

  @media #{$xs-layout} {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 50px 20px 45px 30px;
  }

  .single-contact-info {
    display: flex;
    align-items: center;

    margin-bottom: 40px;

    @media #{$xxs-layout} {
      padding-left: 10%;
      padding-right: 10%;
    }

    .contact-icon {
      margin-right: 20px;

      i {
        font-size: 20px;
        line-height: 40px;

        display: inline-block;

        width: 40px;
        height: 40px;

        transition: all 0.3s ease 0s;
        text-align: center;

        color: #252525;
        border: 1px solid #252525;
        border-radius: 100%;
      }
    }

    .contact-info-dec {
      p {
        line-height: 1;

        margin: 0 0 9px;

        color: #404040;

        img {
          height: 14px;
        }

        a {
          color: #404040;

          &:hover {
            color: $theme-color;
          }
        }

        &:last-child {
          margin: 0;
        }
      }

      a {
        p {
          line-height: 1.3;

          &:hover {
            color: $theme-color;
          }
        }
      }
    }

    // &:hover .contact-icon i {
    //   color: #fff;
    //   background-color: $theme-color;
    // }

    .contact-icon i:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }
}

.contact-social {
  margin-top: 58px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 17px;

    color: #4d4d4d;
  }

  ul {
    li {
      display: inline-block;

      margin: 0 10px;
      width: 30px;

      svg {
        width: 100%;
        height: auto;
        fill: #4d4d4d;

        &:hover {
          fill: $theme-color;
        }

        &.contact-social-facebook:hover {
          fill: #316FF6;
        }

        &.contact-social-instagram:hover {
          .instagram-icon {
            fill: url(#instagramGradient);
          }
        }

        &.contact-social-youtube:hover {
          fill: #FF0000;
        }

        &.contact-social-tiktok:hover {
          fill: #000;
        }
      }

      a {
        font-size: 16px;

        color: #4d4d4d;

        &:hover {
          color: $theme-color;
        }

        i {
          padding-right: 0.5rem;
        }
      }
    }
  }
}

.sidebar-icon-tiktok {
  width: 28px !important;
}

.sidebar-icon-tiktok svg {
  border-radius: 11px;
}

.support-time {
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

.contact-form.newsletter {
  padding: 50px 110px 207px 110px;

  @media #{$lg-layout} {
    padding: 50px 50px 207px 50px;
  }

  @media #{$md-layout} {
    padding: 50px 30px 239px 30px;
  }

  @media #{$xs-layout} {
    padding: 50px 30px 163px 30px;
  }
}

.contact-form {
  padding: 50px 110px 42px 110px;

  background-color: #f3f3f3;

  @media #{$lg-layout} {
    padding: 50px 50px 50px 50px;
  }

  @media #{$md-layout} {
    padding: 50px 30px 380px 30px;
  }

  @media #{$xs-layout} {
    padding: 50px 30px 360px 30px;
  }

  .contact-title {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 36px;

      color: #464646;
    }
  }

  .contact-form-style {

    input,
    textarea {
      height: 40px;
      margin-bottom: 30px;
      padding: 2px 14px;

      color: #000;
      border: 1px solid #c1c1c1;
      background: transparent;
    }

    textarea {
      height: 175px;
      margin-bottom: 0;
      padding: 20px 14px;
    }

    button {
      font-size: 14px;
      font-weight: bold;
      line-height: 1;

      margin-top: 38px;
      padding: 23px;
      min-width: 220px;
      text-align: center;
      border-radius: 100px;

      text-transform: uppercase;

      color: #fff;
      border: none;
      background-color: $theme-color;

      @media #{$xs-layout} {
        width: 100%;
      }

      @media #{$md-layout} {
        width: 70%;
      }

      &:hover {
        background-color: $theme-color-hover;
      }
    }

    .response {
      width: 200px;
      text-align: center;
      display: inline-block;
      border-radius: 5px;
      padding: 5px 15px;
      margin-left: 20px;
      color: #006644;
      background-color: #e3fcef;
      animation: fadeIn 1s;

      @media #{$xs-layout} {
        padding: 12px 12px 12px;
        display: inline-block;
        width: 60%;
        margin-left: 0px;
        margin-top: 20px;
      }

      @media #{$md-layout} {
        padding: 12px 22px 12px;
        display: inline-block;
        width: 40%;
        margin-left: 0px;
        margin-top: 20px;
      }

      &.fadeOut {
        animation-name: fadeOut;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }
    }

    .response_m_large {
      position: absolute;
      margin-top: -10px;
      @media #{$xs-layout} {
        width: 100%;
        position: unset;
        margin-top: 20px;
      }
      @media #{$md-layout} {
        width: 100%;
        position: unset;
        margin-top: 20px;
      }
    }

    .red {
      color: #bf2600;
      background-color: #ffebe6
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    @media #{$xs-layout} {
      height: 220px;
      text-align: center;
    }

    @media #{$md-layout} {
      height: 220px;
      text-align: center;
    }
  }

  p {
    color: #333;

    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

textarea {
  resize: none;
}

.response-login {
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 5px 15px;
  animation: fadeIn 2s;
  color: #bf2600;
  background-color: #ffebe6;

  @media #{$xs-layout} {
    padding: 12px 12px 12px;
    width: 60%;
  }

  @media #{$md-layout} {
    padding: 12px 22px 12px;
    width: 40%;
  }
}