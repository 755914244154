/* SalesBarComponent.module.css */
.container {
    background-color: #f46b33;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
}

.message {
    flex: 1;
}

.dismissButton {
    background-color: brown;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
