@import "variables_hausfabrik";

/*=============================================
=            Helper CSS            =
=============================================*/

@for $i from 0 through 15 {
  .row-#{1 * $i} {
    margin-left: -1px * $i;
    margin-right: -1px * $i;

    & > [class*="col"] {
      padding-left: 1px * $i;
      padding-right: 1px * $i;
    }
  }
}
@for $i from 16 through 80 {
  .row-#{1 * $i} {
    margin-left: -1px * $i;
    margin-right: -1px * $i;

    // Responsive
    @media #{$xl-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$lg-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$md-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$xs-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }

    & > [class*="col"] {
      padding-left: 1px * $i;
      padding-right: 1px * $i;

      // Responsive
      @media #{$xl-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$lg-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$md-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$xs-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

/* no gutters */
.no-gutters {
  margin-left: 0;
  margin-right: 0;
  & > .col,
  & > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
}

/*=====  End of Helper CSS  ======*/
