.popup-google-content-cookie-consent {
    width: 30% !important;
    background-color: white !important;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 1px -1px rgba(0, 0, 0, .12);
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
    .popup-google-content-cookie-consent {
        width: 80% !important;
    }
}

.popup-google-content-cookie-consent-text {
    width: 100%;
    color: black;
    text-align: left;
    margin: 0 0 20px 0 !important;
    flex: 1 0 auto !important;

    p {
        margin-bottom: 2px;
    }

    p a {
        color: $theme-color;
    }

    p a:hover {
        color: $theme-color-hover;
    }

    p.popup-google-content-cookie-consent-text-title {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: 600;
    }
}

.popup-google-content-cookie-consent-wrapper-buttons {
    width: 100%;
}

.popup-google-content-cookie-consent-button {
    width: 45%;
    border-radius: 5px !important;
    background-color: #d2d2d2 !important;
    color: white !important;
    transition: background-color 0.3s;
    margin: 0 !important;

}

.popup-google-content-cookie-consent-button:hover {
    background-color: hsla(0, 0%, 60%, 1) !important;
}

.popup-google-content-cookie-consent-button-yes {
    width: 45%;
    border-radius: 5px !important;
    background-color: $theme-color !important;
    color: white !important;
    transition: background-color 0.3s;
    margin: 0 0 0 5px !important;
    border-radius: 5px !important;
}

.popup-google-content-cookie-consent-button-yes:hover {
    background-color: $theme-color-hover !important;
}

@media screen and (max-width: 767px) {

    .popup-google-content-cookie-consent-button,
    .popup-google-content-cookie-consent-button-yes {
        width: 90%;
    }

    .popup-google-content-cookie-consent-button-yes {
        margin: 5px 0 0 0 !important;
    }
}