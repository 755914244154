/*------ 25. My account Page  ------*/

.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb;

  .panel-heading {
    padding: 0;

    &>button {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0;
    }
  }

  .card-body {
    border-bottom: 1px solid #ebebeb;
  }

  h3.panel-title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    color: #242424;
    display: block;
    padding: 16px 55px;
    position: relative;

    @media #{$xs-layout} {
      line-height: 22px;
      font-size: 14px;
      padding: 16px 30px 16px 40px;
    }

    &:hover {
      color: $theme-color;
    }

    &::before {
      color: #000;
      content: "\f107";
      display: inline-block;
      font-family: "FontAwesome";
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 19px;
    }

    &:hover::before {
      color: $theme-color;
    }

    span {
      color: #242424;
      font-size: 15px;
      left: 20px;
      position: absolute;
      top: 16px;
    }
  }

  h3.panel-title-for-no-collapse {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    color: #242424;
    display: block;
    padding: 16px 55px 16px 40px;
    position: relative;

    @media #{$xs-layout} {
      line-height: 22px;
      font-size: 14px;
      padding: 16px 30px 16px 20px;
    }
  }

  .myaccount-info-wrapper {
    padding: 0;
    background-color: #fff;

    @media (max-width: 767px) {
      padding: 0;

      h1 {
        font-size: 32px;
      }
    }

    .account-info-wrapper {
      // border-bottom: 1px solid #eaeaea;
      // margin-bottom: 28px;
      // padding-bottom: 30px;

      h4 {
        font-size: 15px;
        margin: 0;
        text-transform: uppercase;
      }

      h5 {
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-top: 7px;
      }

      p {}
    }

    .billing-info {
      margin-bottom: 20px;

      @media (max-width: 767px) {
        margin-bottom: 0.5rem;
      }

      label {
        color: #000;
        font-size: 14px;
        text-transform: capitalize;
      }

      input {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #a39b9c;
        color: #000;
        height: 40px;
        padding: 0 15px;
      }
    }

    .billing-back-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .billing-back {
        a {
          color: #000;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;

          &:hover {
            color: $theme-color;
          }

          i {
            font-size: 16px;
            color: $theme-color;
          }
        }
      }

      .billing-btn {
        button {
          border-radius: 100px;
          background-color: $theme-color;
          border: medium none;
          color: #fff;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 16px 35px;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.3s ease 0s;

          &:hover {
            background: $theme-color-hover;
          }
        }
      }
    }

    .entries-wrapper {
      border: 1px solid #eaeaea;
      position: relative;

      .wider-on-mobile {
        margin: 0 auto;
        width: 50%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .account-address-header {
        background-color: #f9f9f9;
        border-radius: 0 0 30px 32px;
        margin: 0 auto;
        width: 90%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .entries-info {
        padding: 10px 20px;

        p {
          color: #000;
          font-size: 15px;
          margin: 0;
        }

        .form-control {
          text-overflow: ellipsis;
        }

        .entries-info {
          padding: 15px 20px 0px 20px;
        }

        .loading-overlay {
          position: absolute;
          margin-top: 7px;
          margin-left: 38px;
        }

        .loading-overlay>* {
          margin: 0;
        }
      }

      .entries-edit-delete {

        a,
        button {
          background: none;
          border: none;
          background-color: #000;
          color: #fff;
          display: inline-block;
          line-height: 1;
          margin: 0 2px;
          padding: 12px 15px;
          text-transform: uppercase;
          font-weight: 500;

          &:hover {
            background-color: $theme-color;
          }

          &.edit {
            background-color: #ffc107;

            &:hover {
              background-color: $theme-color;
            }
          }

          &.save {
            background-color: $success;

            &:hover {
              background-color: $theme-color;
            }
          }
        }
      }
    }
  }
}

.logOut button.ioons-button {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 16px 35px;
  text-transform: uppercase;
  border: none;
  border-radius: 100px;
  color: white;
  background-color: #fc6969 !important;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none;
    background-color: #db5b5b !important;
  }
}

.ioons-button {
  border-radius: 100px;
  background-color: $theme-color !important;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 16px 35px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:hover {
    background: $theme-color-hover !important;
    color: #fff;
    border: none !important;
  }

  &:active {
    background: $theme-color !important;
    border: none !important;
  }

  &:focus {
    background: $theme-color-hover !important;
    border: none !important;
  }

  &:visited {
    background: $theme-color !important;
    color: #fff;
    border: none !important;
  }

  &.cancel {
    background-color: $theme-red !important;

    &:hover,
    &:focus,
    &:visited {
      background-color: darken($danger, 15%);
    }
  }
}

.ioons-button-disabled {
  border-radius: 100px;
  background-color: gray !important;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 5px 35px;
  text-transform: uppercase;
  cursor: not-allowed;
  transition: all 0.3s ease 0s;
}

.ioons-button-disabled-color {
  background-color: gray !important;
}

.save-updated-info-button {
  border-radius: 100px;
  background-color: $theme-gray-2;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  padding: 16px 35px;
  margin-bottom: 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:hover {
    background: #9ea49b;
  }

  &:active {
    background: #9ea49b;
    border: none;
  }

  &:focus {
    background: #9ea49b;
    border: none;
  }

  &:visited {
    background: #9ea49b;
    border: none;
  }
}

.carrier-text {
  color: $theme-color;
  font-weight: 600;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: $theme-color-hover;
    text-decoration: underline;
  }
}


.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #9ea49b;
  border: none;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.form-control {
  padding: 6px 12px 6px 40px;
}

.custom-form-select {
  line-height: 1.5;
  font-weight: 400;
  padding: 6px 12px 6px 40px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #495057;
  ;

  ::-ms-expand {
    display: none;
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.custom-form-select::-webkit-scrollbar {
  width: 10px;
}

.custom-form-select::-webkit-scrollbar-track {
  background-color: $theme-gray-2;
}

.custom-form-select::-webkit-scrollbar-thumb {
  background-color: $theme-color;
}

.custom-form-select::-webkit-scrollbar-thumb:hover {
  background-color: $theme-color-hover;
}

.custom-arrow {
  width: 35px;
  //content: '\f078';
  //font: normal normal normal 17px/1 FontAwesome;
  font: normal normal normal 1.5rem/1 FontAwesome;
  padding: 7px 0px 7px 1px;
  color: $theme-color;
  //right: 11px;
  //top: 6px;
  //height: 34px;
  //padding: 15px 0px 0px 8px;
  border-right: 1px solid $theme-color;
  position: absolute;
  pointer-events: none;
}

.loading-overlay {
  div>div {
    top: 7px !important;
  }
}

.custom-form-select-register {
  background-color: transparent;
  border: 1px solid #a39b9c;
  color: #333;
  font-size: 14px;
  height: 45px;
  margin-bottom: 30x;
  padding: 0 15px;
}

.custom-form-select-checkout {
  font-size: 14px;
  padding-right: 10px;
  padding-left: 20px;
  color: #333;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  width: 100%;
  height: 45px;
}

.invoice-bounded {
  padding-bottom: 16px;
}

.carrier-image {
  width: 100%;
  height: auto;
  max-width: 50px;
  margin: 0 auto;
  margin-right: 10px;
}

.order-number {
  color: $theme-color;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

.delivery-invoice-height {
  @media (min-width: 768px) {
    display: flex;
    flex: 1;
    align-items: stretch;
  }

  @media #{$xs-layout} {
    flex-basis: auto;
  }
}

.responsive-table {
  display: flex;
  flex-direction: column;
}

.order-entry {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}

.order-details {
  display: flex;
  flex-direction: column;
}

.download-button {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .responsive-table {
    display: block;
  }

  .order-entry {
    display: flex;
    flex-direction: row;
  }

  .order-details {
    flex: 1;
    margin-right: 20px;
  }
}

.edit-address-message-success {
  height: 20%;
  border-radius: 10px !important;
  background-color: rgba(223, 240, 216, 0.75) !important;
  margin: 0 !important;
  text-align: center;

  @media #{$xs-layout} {
    padding: 5px !important;
  }
}

.edit-address-message-warning {
  height: 20%;
  border-radius: 10px !important;
  background-color: rgba(252, 248, 227, 0.75) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    margin-right: 5px;
    font-size: 1em !important;
  }
}

.error-message {
  color: red;
  display: block;
  min-height: 24px;

  p {
    color: red;
  }
}

.success-message {
  color: green;
  display: block;
  min-height: 24px;

  p {
    color: green;
  }
}